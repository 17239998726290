import { ContentStoreEntitiesService } from 'src/services/Entities';
import { ContentStoreTagsService } from 'src/services/Tags';
import {
    isIntegerOnly, isAlphaNumeric, isGuid, containsLetter, isProductKey,
} from 'src/utils';
import { createFacet } from 'src/components/common/Search/FacetedSearch';
import { ContentStoreCategoriesService } from 'src/services/Categories';
import { ContentStoreKeywordsService } from 'src/services/Keywords';
import { END_CREATED_TIME_KEY, START_CREATED_TIME_KEY } from 'src/components/common/Search/FacetedSearch/constants';

const ENSEMBLE_LINE_ID_LENGTH = 22;
const NEW_DESIGN_ID_LENGTH = 22;

export const DESIGN_CONCEPT_SLUG = 'Design Concept';

export const ENTITY_DESCRIPTOR_FACET: FacetedSearch.Facet = createFacet({
    name: 'Template Token',
    key: 'entityDescriptor',
    type: 'single',
    bulkSearchSeperator: ',',
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (val.includes('..')) {
            const data = await ContentStoreEntitiesService.getEntities(
                accessToken,
                { entityDescriptor: val },
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.entityDescriptor as string).filter(Boolean) : false;
        }
        return false;
    },
    isLocallyCompatible: (val: string): boolean => val.includes('..') && (val.startsWith('c') || val.startsWith('s')),
});

export const ENTITY_DUC_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'DUC ID',
    key: 'ducIds',
    type: 'single',
    bulkSearchSeperator: ',',
});

export const ENTITY_TEMPLATE_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Template Catalog ID',
    key: 'templateId',
    type: 'single',
    bulkSearchSeperator: ',',
});

export const ENTITY_TAG_FACET: FacetedSearch.Facet = createFacet({
    name: 'Tag',
    key: 'tags',
    type: 'single',
    bulkSearchSeperator: '|',
    autocompleteOptions: async (val: string, accessToken?: string | false): Promise<string[]> => {
        const data = await ContentStoreTagsService.getTags(
            accessToken,
            undefined,
            val,
            0,
            5,
        );

        return data.results.map((x) => x.label).filter(Boolean);
    },
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (isAlphaNumeric(val)) {
            const data = await ContentStoreTagsService.getTags(
                accessToken,
                undefined,
                val,
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.label).filter(Boolean) : false;
        }

        return false;
    },
    isLocallyCompatible: (val: string): boolean => isAlphaNumeric(val) && containsLetter(val),
    // Don't show 'strategist:' part of Tag when it is a Chip label
    formatLabel: (self: FacetedSearch.Facet, value: string): string => (
        (value.startsWith('strategist:')) ? `${self.toString()}${value.substring(11)}` : `${self.toString()}${value}`
    ),
});

export const ENTITY_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Entity ID',
    key: 'ids',
    type: 'single',
    bulkSearchSeperator: ',',
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (isGuid(val)) {
            const data = await ContentStoreEntitiesService.getEntities(accessToken, { ids: [val] }, 0, 1);

            return data.total > 0 ? data.results.map((x) => x.id).filter(Boolean) : false;
        }

        return false;
    },
    isLocallyCompatible: (val: string): boolean => isGuid(val),
});

export const COMBO_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Combo ID',
    key: 'designIds',
    type: 'single',
    isCompatible: async (val: string): Promise<string[] | false> => (
        isIntegerOnly(val) || val.length === NEW_DESIGN_ID_LENGTH ? [val] : false
    ),
    isLocallyCompatible: (val: string): boolean => (
        isIntegerOnly(val) || val.length === NEW_DESIGN_ID_LENGTH
    ),
    bulkSearchSeperator: ',',
});

export const DESIGN_CONCEPT_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Design Concept ID',
    key: 'designConceptId',
    type: 'single',
    bulkSearchSeperator: ',',
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (!Number.isNaN(val)) {
            const data = await ContentStoreEntitiesService.getEntities(
                accessToken,
                { designConceptId: val },
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.designConceptId as string).filter(Boolean) : false;
        }
        return false;
    },
    isLocallyCompatible: (val: string): boolean => isIntegerOnly(val),
});

export const ENSEMBLE_LINE_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Ensemble Line ID',
    key: 'ensembleLineId',
    type: 'single',
    bulkSearchSeperator: ',',
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (isAlphaNumeric(val) && val.length === ENSEMBLE_LINE_ID_LENGTH) {
            const data = await ContentStoreEntitiesService.getEntities(
                accessToken,
                { ensembleLineId: val },
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.ensembleLineId as string).filter(Boolean) : false;
        }
        return false;
    },
    isLocallyCompatible: (val: string): boolean => isAlphaNumeric(val) && val.length === ENSEMBLE_LINE_ID_LENGTH,
});

export const CATEGORY_FACET: FacetedSearch.Facet = createFacet({
    name: 'Category',
    key: 'categories',
    type: 'single',
    bulkSearchSeperator: '|',
    autocompleteOptions: async (val: string, accessToken?: string | false): Promise<string[]> => {
        const data = await ContentStoreCategoriesService.getCategories(
            accessToken,
            val,
            undefined,
            undefined,
            0,
            5,
        );

        return data.results.map((x) => x.internalName).filter(Boolean);
    },
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (isAlphaNumeric(val)) {
            const data = await ContentStoreCategoriesService.getCategories(
                accessToken,
                val,
                undefined,
                undefined,
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.internalName).filter(Boolean) : false;
        }

        return false;
    },
    isLocallyCompatible: (val: string): boolean => isAlphaNumeric(val) && containsLetter(val),
});

export const KEYWORD_FACET: FacetedSearch.Facet = createFacet({
    name: 'Keyword',
    key: 'keywords',
    type: 'single',
    bulkSearchSeperator: '|',
    autocompleteOptions: async (val: string, accessToken?: string | false): Promise<string[]> => {
        const data = await ContentStoreKeywordsService.getKeywords(
            accessToken,
            undefined,
            val,
            undefined,
            undefined,
            0,
            5,
        );

        return data.results.map((x) => x.keywordName).filter(Boolean);
    },
    isCompatible: async (val: string, accessToken?: string | false): Promise<string[] | false> => {
        if (isAlphaNumeric(val)) {
            const data = await ContentStoreKeywordsService.getKeywords(
                accessToken,
                undefined,
                val,
                undefined,
                undefined,
                0,
                5,
            );

            return data.total > 0 ? data.results.map((x) => x.keywordName).filter(Boolean) : false;
        }

        return false;
    },
    isLocallyCompatible: (val: string): boolean => isAlphaNumeric(val) && containsLetter(val),
});

const IS_DTEC_ACTIVE_FACET: FacetedSearch.Facet = createFacet({
    name: 'DTeC Active',
    key: 'isActive',
    type: 'single',
    autocompleteOptions: async (val: string): Promise<string[]> => {
        if (!val) {
            return ['true', 'false'];
        }

        if ('true'.includes(val)) {
            return ['true'];
        }

        if ('false'.includes(val)) {
            return ['false'];
        }

        return [];
    },
    isCompatible: async (val: string): Promise<string[] | false> => (val === 'true' || val === 'false' ? [val] : false),
    isLocallyCompatible: (val: string): boolean => val === 'false' || val === 'true',
});

const IS_APPROVED_FACET: FacetedSearch.Facet = createFacet({
    name: 'Approved',
    key: 'indexable',
    type: 'single',
    autocompleteOptions: async (val: string): Promise<string[]> => {
        if (!val) {
            return ['true', 'false'];
        }

        if ('true'.includes(val)) {
            return ['true'];
        }

        if ('false'.includes(val)) {
            return ['false'];
        }

        return [];
    },
    isCompatible: async (val: string): Promise<string[] | false> => (val === 'true' || val === 'false' ? [val] : false),
    isLocallyCompatible: (val: string): boolean => val === 'false' || val === 'true',
});

const PRODUCT_SEARCH_FACET: FacetedSearch.Facet = createFacet({
    name: 'Product Search',
    key: 'productSearch',
    type: 'single',
    isLocallyCompatible: (val: string): boolean => isProductKey(val) || 'Product Search'.startsWith(val),
});

const ENTITY_PRODUCT_KEY: FacetedSearch.Facet = createFacet({
    name: 'Product Key',
    key: 'productKey',
    type: 'single',
    bulkSearchSeperator: ',',
    isLocallyCompatible: (val: string): boolean => isProductKey(val) || 'Product Key'.startsWith(val),
});

const ENTITY_PRODUCT_VERSION: FacetedSearch.Facet = createFacet({
    name: 'Product Version',
    key: 'productVersion',
    type: 'single',
    hideFromDropdown: true,
    isLocallyCompatible: (val: string): boolean => isIntegerOnly(val),
});

const ENTITY_PRODUCT_OPTIONS: FacetedSearch.Facet = createFacet({
    name: 'Product Options',
    key: 'productOptions',
    hideFromDropdown: true,
    type: 'single',
});

const ENTITY_CREATED_TIME: FacetedSearch.Facet = createFacet({
    name: 'Entity Created Date',
    key: 'entityCreatedTime',
    type: 'single',
});

const ENTITY_START_CREATED_TIME: FacetedSearch.Facet = createFacet({
    name: 'Start Created Date',
    key: START_CREATED_TIME_KEY,
    hideFromDropdown: true,
    type: 'single',
});

const ENTITY_END_CREATED_TIME: FacetedSearch.Facet = createFacet({
    name: 'End Created Date',
    key: END_CREATED_TIME_KEY,
    hideFromDropdown: true,
    type: 'single',
});

export const FACETS: FacetedSearch.Facet[] = [
    PRODUCT_SEARCH_FACET,
    ENTITY_PRODUCT_KEY,
    ENTITY_PRODUCT_OPTIONS,
    ENTITY_DESCRIPTOR_FACET,
    ENTITY_TAG_FACET,
    ENTITY_DUC_ID_FACET,
    KEYWORD_FACET,
    ENTITY_PRODUCT_VERSION,
    IS_APPROVED_FACET,
    CATEGORY_FACET,
    DESIGN_CONCEPT_ID_FACET,
    ENSEMBLE_LINE_ID_FACET,
    COMBO_ID_FACET,
    ENTITY_TEMPLATE_ID_FACET,
    ENTITY_ID_FACET,
    IS_DTEC_ACTIVE_FACET,
    ENTITY_CREATED_TIME,
    ENTITY_START_CREATED_TIME,
    ENTITY_END_CREATED_TIME,
];

export type BulkApprovalStatus = [boolean, boolean];

export const NO_DESIGNS_APPROVED: BulkApprovalStatus = [true, false];
export const ALL_DESIGNS_APPROVED: BulkApprovalStatus = [true, true];
export const SOME_DESIGNS_APPROVED: BulkApprovalStatus = [false, true];
