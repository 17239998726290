import clsx from 'clsx';
import {
    Alert,
    makeStyles,
    Snackbar,
    Skeleton,
    Theme,
} from '@material-ui/core';
import {
    Category, Language, List, MiscellaneousServices,
} from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import {
    ComponentProps, FormEvent, SyntheticEvent, useEffect, useMemo, useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { EntityDetailsEmptyState } from 'src/components/Entity/EntityDetailsDrawer/EntityDetailsEmptyState';
import { LoadingGrid } from 'src/components/common/Loader/LoadingGrid';
import { ErrorGrid } from 'src/components/common/ErrorState/ErrorGrid';
import { useMutateEntities } from 'src/components/Entity/hooks/useMutateEntities';
import { useBulkCultures } from 'src/components/Entity/EntityDetailsDrawer/hooks/useBulkCultures';
import { useBulkIndexable } from 'src/components/Entity/EntityDetailsDrawer/hooks/useBulkIndexable';
import { useBulkPreviews } from 'src/components/Entity/EntityDetailsDrawer/hooks/useBulkPreviews';
import { useBulkSourcedModels } from 'src/components/Entity/EntityDetailsDrawer/hooks/useBulkSourcedModels';
import { TaggingPanel } from 'src/components/Entity/EntityDetailsDrawer/TaggingPanel';
import { PropertiesPanel } from 'src/components/Entity/EntityDetailsDrawer/PropertiesPanel';
import { ApproveButton } from 'src/components/Entity/EntityDetailsDrawer/ApproveButton';
import { ImageSkeleton } from 'src/components/common/images/ImageSkeleton';
import { ErrorImage } from 'src/components/common/images/ErrorImage';
import { MissingImage } from 'src/components/common/images/MissingImage';
import { PreviewGallery } from 'src/components/Entity/EntityDetailsDrawer/PreviewGallery';
import { TabList } from 'src/components/common/Tabs/TabList';
import { Tab } from 'src/components/common/Tabs/Tab';
import { TaxonomyDiscovery } from 'src/components/common/TaxonomyDiscovery';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { CategoriesForm } from 'src/components/Entity/EntityDetailsDrawer/CategoriesForm';
import { CulturesForm } from 'src/components/Entity/EntityDetailsDrawer/CultureForm';
import { KeywordsForm } from 'src/components/Entity/EntityDetailsDrawer/KeywordsForm';
import { TagsForm } from 'src/components/Entity/EntityDetailsDrawer/TagsForm';
import {
    SNACKBAR_TIMEOUT, DataSource, NEW_TAG_LABEL, ENTITY_IDS_BATCH_SIZE, UserEvent,
} from 'src/constants';
import {
    generateKeywordSuggestionsPlaceholderData,
} from 'src/components/Entity/EntityDetailsDrawer/utils/generateKeywordPlaceholderData';
import {
    generateCategorySuggestionsPlaceholderData,
} from 'src/components/Entity/EntityDetailsDrawer/utils/generateCategoryPlaceholderData';
import { useQueryKeywordSuggestions } from 'src/hooks/useQueryKeywordSuggestions';
import { useQueryCategorySuggestions } from 'src/hooks/useQueryCategorySuggestions';
import { HeaderImageThumbnail } from 'src/components/Entity/EntityDetailsDrawer/HeaderImageThumbnail';
import { TitleProperties } from 'src/components/Entity/EntityDetailsDrawer/TitleProperties';
import { HeaderTextColumn } from 'src/components/Entity/EntityDetailsDrawer/HeaderTextColumn';
import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { useQueryEntitiesByBatchedIds } from 'src/hooks/useQueryEntitiesByBatchedIds';
import { LocalizedPreviewButton } from 'src/components/common/LocalizedPreview/LocalizedPreviewButton';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { ALL_DESIGNS_APPROVED, NO_DESIGNS_APPROVED, SOME_DESIGNS_APPROVED } from '../constants';

type Category = Models.ContentStoreApi.V3.SourcedCategory;
type Entity = Models.ContentStoreApi.V3.Entity;
type EntityTag = Models.ContentStoreApi.V3.SourcedTag;
type Keyword = Models.ContentStoreApi.V3.SourcedKeyword;

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave'> {
    ids: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 'fit-content',
    },
    container: {
        flex: 1,
        overflow: 'hidden',
    },
    drawer: {
        width: '75vw',
    },
    enabledButton: {
        position: 'absolute',
        right: theme.spacing(18),
        top: '0',
    },
    propertiesTab: {
        minWidth: 'fit-content',
    },
    tabPanel: {
        height: '100%',
    },
    taggingTabPanel: {
        paddingTop: 0,
    },
    taxonomyTabPanel: {
        padding: 0,
    },
    propertiesTabPanel: {
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    copyButton: {
        marginLeft: theme.spacing(2),
    },
}));

// TODO see if its possible to compose more with SingleEntityDrawer
export const BulkEntityDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        ids,
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('0');
    const [currentDesignId, setCurrentDesignId] = useState(ids[0]);
    const [currentDesignData, setCurrentDesignData] = useState<Models.ContentStoreApi.V3.Entity | undefined>();
    const [suggestionsError, setSuggestionsError] = useState<Error | null>(null);
    const [bulkUpdateErrors, setBulkUpdateErrors] = useState<string[]>([]);
    const setDrawerUpdatedState = useSetRecoilState(drawerUpdatedState);
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const mutation = useMutateEntities(accessToken, ids);

    const keywordSuggestionsPlaceholderData = useMemo(
        () => generateKeywordSuggestionsPlaceholderData(),
        [],
    );

    const categorySuggestionsPlaceholderData = useMemo(
        () => generateCategorySuggestionsPlaceholderData(),
        [],
    );

    const mapEntityToCategories = useMemo(
        () => (entity: Entity): Category[] => Object.values(entity._embedded?.categories || {}),
        [],
    );

    const mapEntityToEntityTags = useMemo(
        () => (entity: Entity): EntityTag[] => Object.values(entity._embedded?.tags || {}),
        [],
    );

    const mapEntityToKeywords = useMemo(
        () => (entity: Entity): Keyword[] => Object.values(entity._embedded?.keywords || {}),
        [],
    );

    // Include no more than 100 entity ids in suggestions requests to avoid long URLs
    const slicedIds = ids.slice(0, ENTITY_IDS_BATCH_SIZE);

    const {
        data,
        error,
        isError,
        isLoading,
        isFetching,
        refetch,
    } = useQueryEntitiesByBatchedIds(accessToken, { ids }, {
        suspense: false,
        refetchInterval: false,
        retry: 1,
    });

    const {
        data: keywordSuggestions,
        error: keywordSuggestionsError,
    } = useQueryKeywordSuggestions(accessToken, { ids: slicedIds }, {
        enabled: !!accessToken && !!ids && !!data,
        suspense: false,
        refetchInterval: false,
        retry: 0,
        placeholderData: keywordSuggestionsPlaceholderData,
    });

    const {
        data: categorySuggestions,
        error: categorySuggestionsError,
    } = useQueryCategorySuggestions(accessToken, { ids: slicedIds }, {
        enabled: !!accessToken && !!ids && !!data,
        suspense: false,
        refetchInterval: false,
        retry: 0,
        placeholderData: categorySuggestionsPlaceholderData,
    });

    useEffect(() => {
        if (!isFetching && data) {
            const newCurrentDesignData = data?.find((result) => result.id === currentDesignId);

            setCurrentDesignData(newCurrentDesignData);
        }
    }, [currentDesignId, data, isFetching]);

    useEffect(() => {
        if (categorySuggestionsError) {
            setSuggestionsError(categorySuggestionsError);
        }
    }, [categorySuggestionsError]);

    useEffect(() => {
        if (keywordSuggestionsError) {
            setSuggestionsError(keywordSuggestionsError);
        }
    }, [keywordSuggestionsError]);

    const [
        sharedCategoriesUsingSuggestions,
        setSharedCategories,
        generateCategoriesSaveableList,
        initializeCategories,
        sharedCategories,
    ] = useBulkSourcedModels(
        data,
        mapEntityToCategories,
        categorySuggestions,
    );

    const [
        sharedCultures,
        setSharedCultures,
        generateCulturesSaveableList,
    ] = useBulkCultures(data);

    const [
        sharedKeywords,
        setSharedKeywords,
        generateKeywordsSavableList,
        initializeKeywords,
    ] = useBulkSourcedModels(data, mapEntityToKeywords, keywordSuggestions);

    const [bulkPreviews] = useBulkPreviews(data);

    const [
        sharedTags,
        setSharedTags,
        generateTagsSavableList,
        initializeTags,
    ] = useBulkSourcedModels(
        data,
        mapEntityToEntityTags,
    );

    const [indexable, setIndexable] = useBulkIndexable(data);

    const ducIds = data?.reduce((accum, d) => (d.ducIds ? accum.concat(d.ducIds) : accum), [] as string[]);
    const uniqueDucIds = [...new Set(ducIds)];
    const { trackEvent } = useTrackEvent();

    /**
     * Handlers
     */

    const generateApprovalButtonText = (): string => {
        if (indexable === NO_DESIGNS_APPROVED) {
            return 'All Unapproved';
        }
        if (indexable === SOME_DESIGNS_APPROVED) {
            const designsCount = data?.length;
            const approvedDesignsCount = data?.filter((d) => d.indexable).length;

            return `${approvedDesignsCount}/${designsCount} Approved`;
        }
        return 'All Approved';
    };

    const handleChangeFactory = <T extends Models.V3.SourcedBase>(
        updateState: (models: T[]) => void,
    ) => (
            event: SyntheticEvent<Element, Event>,
            value: T[] | T,
        ): void => {
            event.preventDefault();

            updateState(value as T[]);
            setDrawerUpdatedState(true);
        };

    const handleCategoryClick = (event: SyntheticEvent<Element, Event>): void => {
        event.preventDefault();

        setActiveTab('2');
    };

    const handleCultureChange = (value: Models.ContentStoreApi.V3.Entity.Culture[]): void => {
        setSharedCultures(value);
        setDrawerUpdatedState(true);
    };

    const handleSelectedDesignChange = (nextIndex: number): void => {
        // @ts-ignore
        const { id } = bulkPreviews[nextIndex];

        setCurrentDesignId(id);
    };

    const handleTabChange = (
        event: SyntheticEvent<Element, Event>,
        newTabIndex: string,
    ): void => {
        event.preventDefault();

        setActiveTab(newTabIndex);
    };

    const handleTabClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        trackEvent({
            eventName: UserEvent.BulkEntityTabSelection,
            eventValue:
                (event.target as HTMLElement).childNodes[1]?.nodeValue ?? undefined,
        });
    };

    const handleAddAllSuggestedKeywords = (): void => {
        const keywordsWithSavedSuggestions = sharedKeywords.map((val) => ({
            ...val,
            source: val.source === DataSource.Suggested ? DataSource.Selected : val.source,
        }));

        setSharedKeywords(keywordsWithSavedSuggestions);
    };

    const handleAddAllSuggestedCategories = (): void => {
        const categoriesWithSavedSuggestions = sharedCategoriesUsingSuggestions.map((val) => ({
            ...val,
            source: val.source === DataSource.Suggested ? DataSource.Selected : val.source,
        }));

        setSharedCategories(categoriesWithSavedSuggestions);
    };

    const handleSave = async (): Promise<void> => {
        trackEvent({ eventName: UserEvent.BulkEditSaved });

        if (data) {
            const updatedEntities = data.reduce((accum, entity) => ({
                ...accum,
                [entity.id]: {
                    ...entity,
                    categories: generateCategoriesSaveableList(entity),
                    cultures: generateCulturesSaveableList(entity),
                    keywords: generateKeywordsSavableList(entity),
                    tags: generateTagsSavableList(entity),
                    // "None" and "All" approved have 'true' in index 0 and their bulk-approval-status in index 1
                    indexable: indexable[0] ? indexable[1] : entity.indexable,
                } as Models.ContentStoreApi.V3.UpdateEntity,
            }), {} as Record<string, Models.ContentStoreApi.V3.UpdateEntity>);

            await mutation.mutateAsync({ accessToken, data: updatedEntities }, {
                onSuccess: (entities) => {
                    initializeCategories(entities.fullfilledResponses);
                    initializeKeywords(entities.fullfilledResponses);
                    initializeTags(entities.fullfilledResponses);
                    if (entities?.rejectedIds) {
                        setBulkUpdateErrors(entities.rejectedIds);
                    }
                },
            });

            // Need to trigger refresh of data otherwise deleted tagging can be readded if saved again
            refetch();
        }
        setDrawerUpdatedState(false);
    };

    const toggleIndexable = (event: FormEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        const newIndexableValue = indexable === ALL_DESIGNS_APPROVED ? NO_DESIGNS_APPROVED : ALL_DESIGNS_APPROVED;

        if (newIndexableValue === ALL_DESIGNS_APPROVED) {
            setSharedTags(sharedTags.map((tag) => ((tag.label === NEW_TAG_LABEL && tag.source === DataSource.Saved)
                ? {
                    ...tag,
                    source: DataSource.Deleted,
                } : tag)));
        }
        setIndexable(newIndexableValue);
    };

    const handleSnackBarClose = (): void => {
        setSuggestionsError(null);
    };

    const handleBulkSnackBarClose = (): void => {
        setBulkUpdateErrors([]);
    };

    return (
        <DetailsDrawer
            open={open}
            PaperProps={{
                className: classes.drawer,
            }}
            onClose={onClose}
            onSave={handleSave}
            {...rest}
        >
            <HeaderImageThumbnail
                textColumn={(
                    <HeaderTextColumn
                        actions={(
                            <>
                                { currentDesignData && (
                                    <LocalizedPreviewButton
                                        entity={currentDesignData}
                                    />
                                )}
                                { isLoading ? (
                                    <Skeleton animation="wave" variant="rectangular">
                                        <ApproveButton approved={false} />
                                    </Skeleton>
                                ) : (
                                    <ApproveButton
                                        approved={indexable === ALL_DESIGNS_APPROVED}
                                        buttonText={generateApprovalButtonText()}
                                        tooltipProps={{
                                            title: indexable === ALL_DESIGNS_APPROVED ? 'Unapprove all' : 'Approve all',
                                        }}
                                        onChange={toggleIndexable}
                                    />
                                )}
                            </>
                        )}
                        title={`Design Details for ${ids.length} designs`}
                    >
                        {(!isLoading && !!currentDesignData) && (
                            <TitleProperties data={currentDesignData} />
                        )}
                    </HeaderTextColumn>
                )}
            >
                {isLoading && (<ImageSkeleton />)}
                {isError && (<ErrorImage />)}
                {(!isLoading && !data) && (<MissingImage />)}
                {(!isLoading && !!data) && (
                    <PreviewGallery
                        items={bulkPreviews}
                        onBeforeSlide={handleSelectedDesignChange}
                    />
                )}
            </HeaderImageThumbnail>
            <TabContext value={activeTab}>
                <TabList
                    boxProps={{
                        sx: { borderBottom: 1, borderColor: 'divider' },
                    }}
                    value={activeTab}
                    variant="scrollable"
                    onChange={handleTabChange}
                    onClick={handleTabClick}
                >
                    <Tab
                        icon={<MiscellaneousServices />}
                        label="Bulk Tagging"
                        value="0"
                    />
                    <Tab
                        className={classes.propertiesTab}
                        disabled={!currentDesignData}
                        icon={<List />}
                        label={`${currentDesignId} Properties`}
                        value="1"
                    />
                    <Tab
                        icon={<Category />}
                        label="Categories"
                        value="2"
                    />
                    <Tab
                        icon={<Language />}
                        label="Cultures"
                        value="3"
                    />
                </TabList>
                <section className={classes.container}>
                    {isLoading && (<LoadingGrid />)}
                    {isError && (
                        <ErrorGrid
                            error={error}
                            title="Failed to get design details"
                        />
                    )}
                    {(!isLoading && !data) && (<EntityDetailsEmptyState />)}
                    {(!isLoading && !!data) && (
                        <>
                            <TabPanel
                                className={clsx(classes.tabPanel, classes.taggingTabPanel)}
                                value="0"
                            >
                                <TaggingPanel>
                                    <KeywordsForm
                                        data={sharedKeywords}
                                        onAddAllSuggestedKeywords={handleAddAllSuggestedKeywords}
                                        onChange={handleChangeFactory(setSharedKeywords)}
                                    />
                                    <TagsForm
                                        data={sharedTags}
                                        onChange={handleChangeFactory(setSharedTags)}
                                    />
                                    <CategoriesForm
                                        data={sharedCategoriesUsingSuggestions}
                                        onAddAllSuggestedCategories={handleAddAllSuggestedCategories}
                                        onChange={handleChangeFactory(setSharedCategories)}
                                        onClick={handleCategoryClick}
                                    />
                                </TaggingPanel>
                            </TabPanel>
                            {!!currentDesignData && (
                                // @ts-expect-error
                                <TabPanel className={clsx(classes.tabPanel)} index={1} value="1">
                                    <PropertiesPanel
                                        className={clsx(classes.propertiesTabPanel)}
                                        data={currentDesignData}
                                    />
                                </TabPanel>
                            )}
                            {/* @ts-ignore */}
                            <TabPanel className={clsx(classes.tabPanel, classes.taxonomyTabPanel)} index={2} value="2">
                                <TaxonomyDiscovery
                                    ducIds={uniqueDucIds}
                                    entityId={ids}
                                    value={sharedCategories}
                                    onChange={handleChangeFactory(setSharedCategories)}
                                />
                            </TabPanel>
                            {/* @ts-ignore */}
                            <TabPanel className={classes.tabPanel} index={3} value="3">
                                <TaggingPanel>
                                    <CulturesForm
                                        bulkDesignCount={ids.length}
                                        values={sharedCultures}
                                        onChange={handleCultureChange}
                                    />
                                </TaggingPanel>
                            </TabPanel>
                        </>
                    )}
                </section>
            </TabContext>
            <Snackbar
                autoHideDuration={SNACKBAR_TIMEOUT}
                open={!!suggestionsError}
                onClose={handleSnackBarClose}
            >
                <Alert severity="error" onClose={handleSnackBarClose}>
                    {`Failed to load suggestions: ${suggestionsError?.message}`}
                </Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={SNACKBAR_TIMEOUT}
                open={!!bulkUpdateErrors.length}
                onClose={handleBulkSnackBarClose}
            >
                <Alert severity="error" onClose={handleBulkSnackBarClose}>
                    {`Failed to update ${bulkUpdateErrors.length} of the requested Entities. Copy list of failed Entity Ids`}
                    <CopyButton className={classes.copyButton} value={bulkUpdateErrors.join(', ')} />
                </Alert>
            </Snackbar>
        </DetailsDrawer>
    );
};
