import { DataSource } from 'src/constants';

function determineOverride<T extends Models.V3.SourcedBase>(original: T | undefined, next: T): T {
    if (!original) {
        return next;
    }

    if (original.source === DataSource.Deleted || original.source === DataSource.Unselected) {
        return original;
    }

    if ((next.source === DataSource.Deleted || next.source === DataSource.Unselected)
        && original.source !== DataSource.Suggested) {
        return next;
    }

    if (original.source === DataSource.Saved) {
        return original;
    }

    if (original.source === DataSource.Selected && next.source === DataSource.Suggested) {
        return original;
    }

    if (original.source === DataSource.Suggested && next.source === DataSource.Selected) {
        return next;
    }

    return next;
}

export const union = <T extends Models.V3.SourcedBase>(
    original: T[],
    updated: T[],
): T[] => {
    // Least efficient solution, find a better one
    // "updated" shows up last in the array in order to allow it to override "original"
    const merged = original.concat(updated);
    const map = merged.reduce((accum, item) => ({
        ...accum,
        [item.href]: determineOverride(accum[item.href], item),
    }), {} as Record<string, T>);

    return Object.values(map);
};
