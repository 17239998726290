import clsx from 'clsx';
import {
    Chip, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { ComponentProps, SyntheticEvent } from 'react';

import { DEFAULT_UNSELECTED_INDEX } from 'src/components/common/Search/FacetedSearch/constants';
import { BulkTag } from 'src/components/common/Search/FacetedSearch/BulkTag';
import { ProductSearchTag } from 'src/components/common/Search/FacetedSearch/ProductOptionSearch/ProductSearchTag';

export interface PropTypes {
    ChipProps?: ComponentProps<typeof Chip>;
    currentSearch: FacetedSearch.FacetedSearchType;
    handleDeleteFacetFactory: (key: string) => (event: SyntheticEvent) => void;
    handleDeleteProductSearch: (event: SyntheticEvent) => void;
    pendingFacet: FacetedSearch.FacetedSearchRecord | null;
    selectedFacet: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    activeFacet: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.htmlFontSize,
        paddingTop: '2.5px',
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
    chip: {
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
}));

export const Tags = (props: PropTypes): JSX.Element => {
    const {
        ChipProps = {},
        currentSearch,
        handleDeleteFacetFactory,
        handleDeleteProductSearch,
        pendingFacet,
        selectedFacet,
    } = props;
    const { className: chipClassName, ...chipRest } = ChipProps;
    const classes = useStyles();
    const currentSearchKeys = Object.keys(currentSearch);

    // If both product key and version are included, display the unique Product Search Chip
    const isProductSearchIncluded = currentSearchKeys.find((key) => currentSearch[key]?.facet?.key === 'productKey')
        && currentSearchKeys.find((key) => currentSearch[key]?.facet?.key === 'productVersion');

    const productOptionsFacetValue = isProductSearchIncluded
        ? Object.values(currentSearch).find((k) => k.productOptionsValue)?.productOptionsValue : undefined;
    const productVersionFacetValue = isProductSearchIncluded
        ? Object.values(currentSearch).find((k) => k.facet?.key === 'productVersion')?.value : undefined;

    return (
        <>
            {!!currentSearchKeys.length && currentSearchKeys.map((key) => {
                if (key !== 'page') {
                    const search = currentSearch[key];

                    if (search.facet) {
                        if (isProductSearchIncluded && search.facet.key === 'productKey') {
                            return (
                                <ProductSearchTag
                                    facet={search}
                                    productOptions={productOptionsFacetValue}
                                    productVersion={productVersionFacetValue}
                                    tabIndex={-1}
                                    variant="filled"
                                    {...chipRest}
                                    className={clsx(classes.chip, chipClassName)}
                                    color={(
                                    selectedFacet !== DEFAULT_UNSELECTED_INDEX
                                        && currentSearchKeys[selectedFacet] === key ? 'primary' : 'default'
                                    )}
                                    data-tag-index={key}
                                    key={key}
                                    onDelete={handleDeleteProductSearch}
                                />
                            );
                        }

                        if (search.bulkValue) {
                            return (
                                <BulkTag
                                    facet={search}
                                    tabIndex={-1}
                                    variant="filled"
                                    {...chipRest}
                                    className={clsx(classes.chip, chipClassName)}
                                    color={(
                                    selectedFacet !== DEFAULT_UNSELECTED_INDEX
                                        && currentSearchKeys[selectedFacet] === key ? 'primary' : 'default'
                                    )}
                                    data-tag-index={key}
                                    key={key}
                                    onDelete={handleDeleteFacetFactory(key)}
                                />
                            );
                        }
                        if (search.value) {
                            if (isProductSearchIncluded && (search.facet.key === 'productVersion' || (search.facet.key === 'productOptions'))) {
                                return null;
                            }
                            return (
                                <Chip
                                    label={search.facet.formatLabel(search.facet, search.value)}
                                    tabIndex={-1}
                                    variant="filled"
                                    {...chipRest}
                                    className={clsx(classes.chip, chipClassName)}
                                    color={(
                                    selectedFacet !== DEFAULT_UNSELECTED_INDEX
                                        && currentSearchKeys[selectedFacet] === key ? 'primary' : 'default'
                                    )}
                                    data-tag-index={key}
                                    key={key}
                                    onDelete={handleDeleteFacetFactory(key)}
                                />
                            );
                        }
                    }
                }
                return null;
            })}
            {!!pendingFacet?.facet && (
                <Typography
                    className={classes.activeFacet}
                    component="span"
                    key={pendingFacet.facet.key}
                    variant="body2"
                >
                    {pendingFacet.facet.toString()}
                </Typography>
            )}
        </>
    );
};
