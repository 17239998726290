import { Grid } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ZeroState } from 'src/components/common/Tagging/ZeroState';
import { DataSource } from 'src/constants';

export interface PropTypes<T> extends ComponentProps<typeof Grid> {
    children: (item: T) => JSX.Element;
    data: T[];
}

const sort = <T extends Models.V3.SourcedBase>(a: T, b: T): number => {
    if (
        a.source === DataSource.Saved && b.source !== DataSource.Saved
        || a.source === DataSource.Selected && b.source === DataSource.Suggested
    ) {
        return -1;
    }

    if (
        a.source !== DataSource.Saved && b.source === DataSource.Saved
        || b.source === DataSource.Selected && a.source === DataSource.Suggested
    ) {
        return 1;
    }

    return 0;
};

export const TaggingData = <T extends Models.V3.SourcedBase>(props: PropTypes<T>): JSX.Element => {
    const {
        children,
        data,
        ...rest
    } = props;

    return (
        <Grid container spacing={2} {...rest}>
            {!data.filter((item) => item.source !== DataSource.Deleted && item.source !== DataSource.Unselected).length
                && (<ZeroState />)}
            {!!data.length && data
                .filter((item) => item.source !== DataSource.Deleted && item.source !== DataSource.Unselected)
                .sort(sort)
                .map((item) => (<Grid item key={item.id}>{children(item)}</Grid>))}
        </Grid>
    );
};
