/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Logger,
    consoleTransporter,
    rollbarTransporter,
    ILogger,
    Transport,
} from '@vp/js-logger';
import { v4 as uuid } from 'uuid';
import { config } from 'src/lib/config';
import { auth } from 'src/lib/auth';

// TODO use vp session from browser
const sessionKey = uuid();
const profile = auth.isLoggedIn() ? auth.getProfile() : undefined;

const rollbarConfig = {
    ...config.rollbar.notifier,
    payload: {
        ...config.rollbar.notifier.payload,
        sessionKey,
        context: window.location.toString(),
        person: {
            email: profile?.email,
            id: profile?.email || '', // TODO: Investigate why this broke
        },
    },
};

const rollbarTransport = rollbarTransporter(process.env.NODE_ENV!, rollbarConfig, config.rollbar.global);

const transports: Transport[] = [consoleTransporter, rollbarTransport];

if (process.env.NODE_ENV === 'development') {
    transports.unshift(consoleTransporter);
}

const logger = new Logger({
    ...config.logger,
    transports,
});

export function getLogger(): ILogger {
    return logger;
}
